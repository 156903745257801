import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

/**
 * url의 search params(query string)와 sync(deep-link)된 state를 사용할 수 있게 하는 hook입니다.
 * @param initialState state의 초기값. useState의 초기값과 동일함.
 * @param paramName search param의 key를 설정합니다.
 * @param serialize state를 search param의 value로 변환할 때 쓰이는 call-back 함수입니다. 간단히 말해, state를 str으로 어떻게 변환시킬지 지정하는 함수입니다.
 * @param deserialize serialize와 반대로, search param의 value를 state로 어떻게 변환시킬지 지정하는 call-back 함수입니다.
 */
export const useStatefulUrl = <T>(initialState: T, paramName: string, serialize: (s: T) => string, deserialize: (serializedState: string) => T) => {
  const router = useRouter();
  const params = router.query;
  const existingValue = params[paramName] as string;
  const [state, setState] = useState<T>(initialState);

  // 렌더링 후, existingValue의 변경을 감지하여 state와 비교하고 저장
  useEffect(() => {
    if (existingValue && deserialize(existingValue) !== state) {
      setState(deserialize(existingValue));
    }
  }, [existingValue]);

  // state을 변경하고 변경된 state을 url의 search param에 저장하는 handler
  const handleStateChange = (newState: T) => {
    setState(newState);
    const searchParams = new URLSearchParams(window.location.search);

    // new state가 유효하다면 url의 search param에 저장
    if (newState && serialize(newState)) {
      searchParams.set(paramName, serialize(newState));
    } else {
      // state가 삭제되면 search param도 삭제
      searchParams.delete(paramName);
    }

    const pathName = window.location.pathname;
    const newUrl = searchParams.toString() ? pathName + '?' + searchParams.toString() : pathName;
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
  };
  return [state, handleStateChange] as const;
};
