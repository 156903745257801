import styled from 'styled-components';
import { ThemeOptions } from '@mui/material';
import { IDropdownWrapperProps, IButtonBaseProps } from '@components/search/common/common.types';

export const ButtonBase = styled.button<IButtonBaseProps>`
  height: ${(props) => props.height}px;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid ${(props) => (props.isActive ? '#8E8EFF' : 'rgba(188, 188, 190, 0.5)')};
  border-radius: 4px;
  padding: 8px 12px 8px 16px;
  background-color: ${(props) => (props.isActive ? 'rgba(78, 78, 255, 0.04)' : '#FFFFFF')};
`;

export const FiltersFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  .tabs-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
`;

export const SortFlex = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ResultCountFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DropdownWrapper = styled.div<IDropdownWrapperProps>`
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 24px;
  gap: 12px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 8px;
  top: ${(props) => props.offsetTop}px;
  left: ${(props) => props.offsetLeft}px;
  width: calc(100% - 30px);
  height: auto;
  max-width: ${(props) => props.width};
  max-height: ${(props) => props.height};
  overflow-x: ${(props) => (props.overflow ? props.overflow : 'hidden')};
  overflow-y: ${(props) => (props.overflow ? props.overflow : 'hidden')};

  @supports (overflow: overlay) {
    overflow-y: overlay;
    ::-webkit-scrollbar {
      width: 14px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(136, 136, 138, 0.5);
      width: 6px;
      border: solid #ffffff 4px;
      border-radius: 12px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(136, 136, 138);
    }
  }
`;

export const FilterWrapper = styled.div`
  position: relative;
`;

export const ChipWrapper = styled.div`
  border-radius: 16px;
  background: rgba(78, 78, 255, 0.08);
  display: flex;
  align-items: center;
  padding: 3px 4px;
  .textWrapper {
    padding: 0 6px;
  }
`;

export const CheckboxLabel = styled('label')((props) => {
  const theme = props.theme as ThemeOptions;
  return {
    display: 'flex',
    height: 24,
    alignItems: 'center',
    cursor: 'pointer',
    flexBasis: '31%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '45%',
    },
    'input[type="checkbox"]': {
      visibility: 'hidden',
      width: 0,
      height: 0,
    },
    '.checkbox': {
      display: 'inline-block',
    },
    '.label-text': {
      marginLeft: 8,
    },
  };
});

export const ErrorTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  width: 100%;
  height: 100%;
`;
