import React from 'react';
import { DropdownWrapper, ButtonBase, ChipWrapper, CheckboxLabel } from '@components/search/common/common.styles';
import { IDropdownProps, IDropdownWrapperProps, IButtonBaseProps, IButtonProps, IChipProps, ICheckboxProps } from '@components/search/common/common.types';
import { CustomText } from '@components/ui';
import { CheckedCheckboxIcon, ChipCancelIcon, UncheckedCheckboxIcon, VShapedIcon } from '@components/search/icons';

export const FilterButton = React.forwardRef((props: IButtonProps, ref: React.MutableRefObject<HTMLButtonElement>) => {
  const filterButtonBaseProps: IButtonBaseProps = {
    height: props.height,
    isActive: props.isActive,
    onClick: props.onClick,
  };

  return (
    <ButtonBase {...filterButtonBaseProps} ref={ref}>
      <CustomText weight={600} lineHeight={'20px'} color={'#313135'}>
        {props.children}
      </CustomText>
      <VShapedIcon />
    </ButtonBase>
  );
});

export const DropDown = React.forwardRef((props: IDropdownProps, ref: React.MutableRefObject<HTMLDivElement>) => {
  const dropdownWrapperProps: IDropdownWrapperProps = {
    height: props.dropdownHeight,
    width: props.dropdownWidth,
    offsetTop: props.offsetTop,
    offsetLeft: props.offsetLeft,
    overflow: props.overflow,
  };
  return (
    <DropdownWrapper {...dropdownWrapperProps} ref={ref}>
      {props.children}
    </DropdownWrapper>
  );
});

export const Chip = (props: IChipProps) => {
  return (
    <ChipWrapper>
      <div className={'textWrapper'}>
        <CustomText weight={400} size={13} lineHeight={'18px'} letterSpacing={'0.16px'} color={'rgba(0, 0, 0, 0.87)'}>
          {props.children}
        </CustomText>
      </div>
      <button onClick={props.onClick}>
        <ChipCancelIcon />
      </button>
    </ChipWrapper>
  );
};

export const Checkbox = (props: ICheckboxProps) => {
  return (
    <CheckboxLabel>
      <input
        type="checkbox"
        onChange={() => {
          props.onChange(props.labelText);
        }}
      />
      <span className={'checkbox'}>{props.isActive ? <CheckedCheckboxIcon /> : <UncheckedCheckboxIcon />}</span>
      <CustomText weight={400} lineHeight={'20px'} className={'label-text'}>
        {props.labelText}
      </CustomText>
    </CheckboxLabel>
  );
};
