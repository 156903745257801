import styled from 'styled-components';

export const ChipsWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  ${(props) => props.isMobile && 'flex-direction: column;'}
  .chips-wrapper {
    position: relative;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .button-wrapper {
    margin-left: auto;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button-wrapper-mobile {
    padding: 4px 8px 8px;
    width: max-content;
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;
