import React from 'react';

export const VShapedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0002 12.9177C9.80554 12.9181 9.61684 12.8503 9.46692 12.7261L4.46692 8.55941C4.11253 8.26485 4.06403 7.73879 4.35858 7.38441C4.65313 7.03002 5.1792 6.98152 5.53358 7.27607L10.0002 11.0094L14.4669 7.40941C14.6391 7.2696 14.8599 7.20418 15.0804 7.22764C15.3009 7.2511 15.503 7.36151 15.6419 7.53441C15.7962 7.70762 15.8713 7.9373 15.8492 8.16821C15.827 8.39911 15.7096 8.61033 15.5252 8.75107L10.5252 12.7761C10.371 12.8807 10.1862 12.9305 10.0002 12.9177Z"
        fill="#212B36"
      />
    </svg>
  );
};

export const ChipCancelIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99992 1.33333C4.31325 1.33333 1.33325 4.31333 1.33325 8C1.33325 11.6867 4.31325 14.6667 7.99992 14.6667C11.6866 14.6667 14.6666 11.6867 14.6666 8C14.6666 4.31333 11.6866 1.33333 7.99992 1.33333ZM11.3333 10.3933L10.3933 11.3333L7.99992 8.93999L5.60659 11.3333L4.66659 10.3933L7.05992 8L4.66659 5.60666L5.60659 4.66666L7.99992 7.06L10.3933 4.66666L11.3333 5.60666L8.93992 8L11.3333 10.3933Z"
        fill="#4E4EFF"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export const ClearIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.124 4.12373V7.12373H11.124" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.9535 10.4178C13.6541 11.5352 12.9766 12.5142 12.0363 13.188C11.096 13.8617 9.9512 14.1887 8.7969 14.113C7.6426 14.0374 6.55024 13.5638 5.70595 12.773C4.86166 11.9823 4.31768 10.9232 4.16669 9.77635C4.0157 8.62947 4.26705 7.46572 4.8779 6.48339C5.48876 5.50105 6.42133 4.76091 7.51671 4.38908C8.6121 4.01725 9.80253 4.03673 10.8852 4.4442C11.9678 4.85168 12.8756 5.62193 13.454 6.62373"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const UncheckedCheckboxIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.60012" y="2.6" width="18.8" height="18.8" rx="3.4" stroke="#A2A2A4" strokeWidth="1.2" />
    </svg>
  );
};

export const CheckedCheckboxIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.00012" y="2" width="20" height="20" rx="4" fill="#313135" />
      <path d="M15.6369 9L10.5457 14.0912L8.00012 11.5456" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const UncheckedRadioIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.9999" cy="12" r="8.85" stroke="#A2A2A4" strokeWidth="1.5" />
    </svg>
  );
};

export const CheckedRadioIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.9999" cy="12" r="8.85" stroke="#313135" strokeWidth="1.5" />
      <circle cx="12" cy="12" r="5" fill="#313135" />
    </svg>
  );
};

export const DisabledRadioIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.9999" cy="12" r="8.85" stroke="#BCBCBE" strokeWidth="1.5" />
      <circle cx="12" cy="12" r="5" fill="#BCBCBE" />
    </svg>
  );
};
