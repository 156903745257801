import { useTranslation } from 'next-i18next';
import { ClearFiltersButton } from '@components/search/filters/ClearFilters/ClearFilters.styles';
import { ClearIcon } from '@components/search/icons';
import { CustomText } from '@components/ui';
import { IClearFiltersProps } from './ClearFilters.types';

const ClearFilters = (props: IClearFiltersProps) => {
  const { t } = useTranslation(['search']);
  return (
    <ClearFiltersButton onClick={props.onClick}>
      <CustomText weight={700} size={13} lineHeight={'22px'} color={'#212B36'}>
        {t('search:filters.clearFilters')}
      </CustomText>
      <ClearIcon />
    </ClearFiltersButton>
  );
};

export default ClearFilters;
